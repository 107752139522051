import Vue from "vue";
import VueRouter from "vue-router";
import versionTood from "../utils/versionUpdate";

Vue.use(VueRouter);

const routes = [
  // AI助理
  {
    name: "assistantIndex",
    path: "/assistantIndex",
    redirect: "/assistant",
    component: () => import("views/assistant/index.vue"),
    children: [
      {
        name: "assistant",
        path: "/assistant",
        component: () => import("views/assistant/assistant.vue"),
      },
      {
        name: "personPage",
        path: "/personPage",
        component: () => import("views/training/index.vue"),
      },
      {
        name: "teaching",
        path: "/teaching",
        component: () => import("views/assistant/teaching.vue"),
      },
      {
        name: "chatMsgList",
        path: "/chatMsgList",
        component: () => import("views/chatMsgList/index.vue"),
      },
      {
        name: "answerSetting",
        path: "/answerSetting",
        component: () => import("views/answerSetting/index.vue"),
      },
      {
        name: "professional",
        path: "/professional",
        component: () => import("views/professional/index.vue"),
      },
      {
        name: "information",
        path: "/information",
        component: () => import("views/assistant/information.vue"),
      },
      {
        name: "assistantDetails",
        path: "/assistantDetails",
        component: () => import("views/assistantDetails/index.vue"),
      },
      {
        name: "training",
        path: "/training",

        component: () => import("views/assistant/personPage.vue"),
      },
      // 我的消息对话
      {
        name: "dialogue",
        path: "/dialogue",

        component: () => import("views/assistant/dialogue.vue"),
      },
    ],
  },
  {
    path: "/index",
    name: "index",
    redirect: "/photo",
    component: () => import("@/views/Photopage/index.vue"),
    children: [
      {
        path: "/photo",
        name: "photo",
        component: () => import("@/views/Photopage/PhotoList.vue"),
      },
      {
        path: "/photomackup",
        name: "",
        component: () => import("views/Photopage/Photomackup.vue"),
        path: "/photoundertone",
        name: "",
        component: () => import("views/Photopage/PhotoUndertone.vue"),
      },
      {
        path: "/photomistake",
        name: "",
        component: () => import("views/Photopage/Photomistake.vue"),
      },
      {
        path: "/photomackup",
        name: "",
        component: () => import("views/Photopage/Photomackup.vue"),
      },

      // 相机页面
      {
        path: "/camera",
        name: "camera",
        component: () => import("views/Photopage/camera.vue"),
      },
      {
        path: "/phone",
        name: "",
        component: () => import("views/phone/phone.vue"),
      },
      {
        path: "/photoEdit",
        name: "",
        component: () => import("views/Photopage/PhotoEdit.vue"),
      },
      {
        path: "/photoView",
        name: "",
        component: () => import("@/views/Photopage/PhotoView.vue"),
      },
      {
        path: "/photoCollect",
        name: "",
        component: () => import("views/Photopage/PhotoCollect.vue"),
      },
    ],
  },
  // 合规证件照
  {
    path: "/index",
    name: "index",
    redirect: "/photo_liance",
    component: () => import("@/views/Photopage_text/index.vue"),
    children: [
      {
        path: "/photo_liance",
        name: "photo_liance",
        component: () => import("@/views/Photopage_text/PhotoList.vue"),
      },
      {
        path: "/photomackup_liance",
        name: "",
        component: () => import("views/Photopage_text/Photomackup.vue"),
        path: "/photoundertone_liance",
        name: "",
        component: () => import("views/Photopage_text/PhotoUndertone.vue"),
      },
      {
        path: "/photomistake_liance",
        name: "",
        component: () => import("views/Photopage_text/Photomistake.vue"),
      },
      {
        path: "/photomackup_liance",
        name: "",
        component: () => import("views/Photopage_text/Photomackup.vue"),
      },

      // 相机页面
      {
        path: "/camera_liance",
        name: "camera_liance",
        component: () => import("views/Photopage_text/camera.vue"),
      },
      {
        path: "/photoEdit_liance",
        name: "",
        component: () => import("views/Photopage_text/PhotoEdit.vue"),
      },
      {
        path: "/photoView_liance",
        name: "",
        component: () => import("@/views/Photopage_text/PhotoView.vue"),
      },
      {
        path: "/photoCollect_liance",
        name: "",
        component: () => import("views/Photopage_text/PhotoCollect.vue"),
      },
    ],
  },
  // 数学公式渲染
  {
    path: "/mathjax",
    name: "",
    component: () => import("views/mathjax/mathjax.vue"),
  },
  {
    path: "/restassured",
    name: "restassured",
    component: () => import("views/restassured/index"),
  },
  {
    path: "/SoftwareDownload",
    name: "SoftwareDownload",
    component: () => import("views/SoftwareDownload/index"),
  },
  {
    path: "/envelope",
    name: "envelope",
    component: () => import("views/envelope/index"),
  },
  {
    path: "/VIPpage",
    name: "VIPpage",
    component: () => import("views/VIPpage/VIPpage"),
  },
  {
    path: "/taskPage",
    name: "taskPage",
    component: () => import("views/VIPpage/taskPage"),
  },
  {
    path: "/Practice",
    name: "Practice",
    component: () => import("views/VIPpage/Practice"),
  },
  {
    path: "/AIagreements",
    name: "AIagreements",
    component: () => import("views/VIPpage/AIagreements"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("views/VIPpage/privacy"),
  },
  {
    path: "/privacyBossIp",
    name: "privacyBossIp",
    component: () => import("views/VIPpage/privacyBossIp"),
  },
  {
    path: "/agencyContract",
    name: "agencyContract",
    component: () => import("views/VIPpage/agencyContract"),
  },
  {
    path: "/potential",
    name: "potential",
    component: () => import("views/VIPpage/potential"),
  },
  {
    path: "/potentialAI",
    name: "potentialAI",
    component: () => import("views/VIPpage/potentialAI"),
  },
  {
    path: "/agreement2D",
    name: "agreement2D",
    component: () => import("views/VIPpage/agreement2D"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("views/VIPpage/agreement"),
  },
  {
    path: "/addition",
    name: "addition",
    component: () => import("views/VIPpage/addition"),
  },
  {
    path: "/addprivacy",
    name: "addprivacy",
    component: () => import("views/VIPpage/addprivacy"),
  },
  {
    path: "/AIagreement",
    name: "AIagreement",
    component: () => import("views/VIPpage/AIagreement"),
  },
  {
    path: "/partnershipAgreement",
    name: "partnershipAgreement",
    component: () => import("views/VIPpage/partnershipAgreement"),
  },
  {
    path: "/marginAgreement",
    name: "marginAgreement",
    component: () => import("views/VIPpage/marginAgreement"),
  },
  {
    path: "/",
    name: "",
    component: () => import("views/toolbar/Home"),
    redirect: "/toolbar",
  },
  {
    path: "/PreviewWord",
    name: "PreviewWord",
    component: () => import("views/toolbar/PreviewWord"),
  },
  //编辑器
  {
    name: "toolHome",
    path: "/toolHome",
    meta: {
      show: false,
    },
    // component: () => import('views/toolbar/toolbar.vue'),
    component: () => import("views/editor/editIndex.vue"),
  },
  // 编辑器首页
  {
    name: "toolbar",
    path: "/toolbar",
    meta: {
      show: false,
      cache: true,
    },
    component: () => import("views/toolbar/Home.vue"),
  },
  {
    name: "pptResult",
    path: "/pptResult",
    meta: {
      show: false,
    },
    component: () => import("views/toolbar/pptResult.vue"),
  },

  // markdown编辑器
  {
    name: "markdown",
    path: "/markdown",
    component: () => import("views/markdown/index.vue"),
  },
  {
    name: "markdownEditor",
    path: "/markdownEditor",
    component: () => import("views/markdown/editor/index.vue"),
  },
  {
    name: "markdownSearch",
    path: "/markdownSearch",
    component: () => import("views/markdown/search/index.vue"),
  },
  // 海报&名片编辑
  {
    name: 'imageEditor',
    path: '/imageEditor',
    component: () => import('views/imageEditor/index.vue')
  },
  {
    name: "wxAlphaTest",
    path: "/wxAlphaTest/markdown",
    component: () => import("views/markdown/index.vue"),
  },
  // 编辑器更多案例
  {
    name: "caseMore",
    path: "/caseMore",
    meta: {
      show: false,
    },
    component: () => import("views/toolbar/caseMore.vue"),
  },
  // 编辑器功能区
  {
    name: "Areation",
    path: "/Areation",
    meta: {
      show: false,
    },
    component: () => import("views/toolbar/Areation.vue"),
  },
  // 简历详情
  {
    name: "resumeDetail",
    path: "/resumeDetail",
    meta: {
      show: false,
    },
    component: () => import("views/toolbar/resumeDetail.vue"),
  },
  // 上传本地文件
  {
    name: "uploadFile",
    path: "/uploadFile",
    meta: {
      show: false,
    },
    component: () => import("views/toolbar/uploadFile.vue"),
  },
  // ai对比
  {
    name: "Compare",
    path: "/Compare",
    meta: {
      show: false,
    },
    component: () => import("views/Compare/Compare.vue"),
  },
  // PPT预览
  {
    name: "previewPPT",
    path: "/previewPPT",
    meta: {
      show: false,
    },
    component: () => import("views/Compare/previewPPT.vue"),
  },
  // 名片图下载
  {
    name: "card",
    path: "/card",
    // meta: {
    //   show: false,
    // },
    component: () => import("views/card/card.vue"),
  },
  // 邀请规则
  {
    name: "invitationRules",
    path: "/invitationRules",
    component: () => import("views/invitationRules/index.vue"),
  },
  {
    name: "serviceRules",
    path: "/serviceRules",
    component: () => import("views/invitationRules/serviceRules.vue"),
  },
  {
    name: "tradeRules",
    path: "/tradeRules",
    component: () => import("views/invitationRules/tradeRules.vue"),
  },
  {
    name: "disputeRules",
    path: "/disputeRules",
    component: () => import("views/invitationRules/disputeRules.vue"),
  },
  // 红包
  {
    name: "redPacket",
    path: "/redPacket",
    component: () => import("views/redPacket/index.vue"),
  },
  // 视频播放
  {
    name: "video",
    path: "/video",
    // meta: {
    //   show: false,
    // },
    component: () => import("views/video/video.vue"),
  },
  // 下载word文档
  {
    name: "downloadWord",
    path: "/downloadWord",
    // meta: {
    //   show: false,
    // },
    component: () => import("views/downloadWord/downloadWord.vue"),
  },
  {
    name: "dyUploadFile",
    path: "/dyUploadFile",
    component: () => import("views/dyUploadFile/dyUploadFile.vue"),
  },
  {
    name: "aiShenLunUserAgreement",
    path: "/aiShenLunUserAgreement",
    component: () => import("views/aiShenLun/userAgreement.vue"),
  },
  {
    name: "aiShenLunUserPolicy",
    path: "/aiShenLunUserPolicy",
    component: () => import("views/aiShenLun/userPolicy.vue"),
  },
  {
    name: "aiShenLunUserPotential",
    path: "/aiShenLunUserPotential",
    component: () => import("views/aiShenLun/userPotential.vue"),
  },
  {
    path: "/settleInService",
    name: "settleInService",
    component: () => import("views/VIPpage/settleInService"),
  },
  {
    path: "/particularsRule",
    name: "particularsRule",
    component: () => import("views/VIPpage/particularsRule"),
  },
  {
    path: "/goodFaithGuarantee", // 诚信保证服务协议
    name: "goodFaithGuarantee",
    component: () => import("views/VIPpage/goodFaithGuarantee"),
  },
  {
    path: "/informationHandleRule", // 挖潜智库滥发信息处理规则
    name: "informationHandleRule",
    component: () => import("views/VIPpage/informationHandleRule"),
  },
  {
    path: "/serviceSettleInAgreement", // 挖潜智库平台服务商入驻协议
    name: "serviceSettleInAgreement",
    component: () => import("views/VIPpage/serviceSettleInAgreement"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  versionTood.isNewVersion();

  next();
});
export default router;
